import React, { useState, useEffect } from 'react';
import 'draft-js/dist/Draft.css';
import axios from 'axios';
import styled from 'styled-components';

// Styled Components
const BuilderContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: ${({ isViewingEmail }) => (isViewingEmail ? '50px' : '0px')};
  @media (max-width: 768px) {
    margin-left: 0px;
    width: 100%;
    margin-top: ${({ isViewingEmail }) => (isViewingEmail ? '70px' : '0px')};
  }
`;

const BuilderBlock = styled.div`
  display: flex;
  flex: 1;
  width: calc(100% - 100px);
  margin: 0px;
  @media (max-width: 768px) {
    flex: 1 1 100%;
    flex-direction: column;
  }
`;

const FormBlock = styled.div`
  background: white;
  padding: 20px;
  padding-bottom: 0px;
  margin: 10px;
  margin-top: 60px;
  border-radius: 10px;
  height: calc(100vh - 150px);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  flex: 0 0 40%;
  @media (max-width: 768px) {
    flex: 1;
  }
`;

const PreviewBlock = styled.div`
  background: white;
  min-width: 780px;
  padding: 20px;
  padding-bottom: 0px;
  margin: 10px;
  margin-top: 60px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  flex: 0 0 60%;
  height: calc(100vh - 150px);
  overflow-y: auto;  // Enable vertical scroll
  @media (max-width: 768px) {
    flex: 1;
  }
`;

const TopMenuBar = styled.div`
  width: calc(100% - 290px); // Adjust for sidebar width
  background-color: #007bff;
  color: white;
  padding: 10px;  // Reduced padding for a tighter fit
  margin-left: 290px;  // Align with the sidebar
  margin-top: 0px;
  display: flex;
  justify-content: left;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  padding-left: 50px;
  @media (max-width: 768px) {
    margin: 0px;
    padding: 0px;
    width: 100%;
  }
`;

const MenuButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  @media (max-width: 768px) {
    margin-bottom: 10px;
    margin-top: 10px;
  }
  &:hover {
    background-color: #0056b3;
  }
`;

const MenuButtonText = styled.span`
  margin-top: 5px;
  font-size: 14px;
  text-align: center;  // Ensure text is centered
`;

const Dropdown = styled.select`
  padding: 10px;
  margin-left: 20px;
  margin-bottom: 0px;
  width: 200px;
`;

const ArticleBuilder = () => {
  const [selectedBlog, setSelectedBlog] = useState('');
  const [blogNames, setBlogNames] = useState([]);
  const [content, setContent] = useState('');
  const [url, setUrl] = useState('');
  const [siteId, setSiteId] = useState('');

  const [nextIssueDetails, setNextIssueDetails] = useState({
    issuetitle: '',
    issuename: '',
    issuedate: '',
    template_cols: '',
    stylesheet: '',
    stylesheet_url: ''
  });

  useEffect(() => {
    fetchBlogNames();
  }, []);

  useEffect(() => {
    const fetchNextIssueDetails = async () => {
      try {
        if (siteId) {
          const response = await axios.get('/api/next-issue-details', {
            params: { site_id: siteId }
          });
          setNextIssueDetails(response.data);
        }
      } catch (error) {
        console.error('Error fetching next issue details:', error);
      }
    };

    fetchNextIssueDetails();
  }, [siteId]);

  useEffect(() => {
    if (url && nextIssueDetails.issuename) {
      const fetchNewsletter = async () => {
        try {
          const response = await axios.get('/api/newsletter', {
            params: { url: url, style: nextIssueDetails.stylesheet_url }
          });
          setContent(response.data.content);
        } catch (error) {
          console.error('Error fetching next issue details:', error);
        }

      };
      fetchNewsletter();
    }
  }, [url, nextIssueDetails.issuename, nextIssueDetails.stylesheet_url]);

  const fetchBlogNames = async () => {
    try {
      const response = await axios.get('/api/blog-names');
      setBlogNames(response.data);
    } catch (error) {
      console.error('Error fetching blog names:', error);
    }
  };


  const handleBlogChange = async (event) => {
    const selectedBlogId = Number(event.target.value);
    setSelectedBlog(selectedBlogId);
    const selectedBlogData = blogNames.find((blog) => blog.blog_id === selectedBlogId);
    if (selectedBlogData) {
      setSiteId(selectedBlogData.blog_id);
    }
  };

  useEffect(() => {
    if (nextIssueDetails.issuename) {
      const selectedBlogData = blogNames.find((blog) => blog.blog_id === selectedBlog);
      if (selectedBlogData) {
        setUrl(`https://${selectedBlogData.domain}/${nextIssueDetails.issuename}/`);
      }
    }
  }, [nextIssueDetails.issuename, selectedBlog, blogNames ]);

  return (
    <BuilderContainer>
      <TopMenuBar id="top-menu-bar">
        <MenuButtonText>Accounts: </MenuButtonText>
        <Dropdown id="accounts" value={selectedBlog} onChange={handleBlogChange}>
          <option value="">Select an account</option>
          {blogNames.map((blog) => (
            <option key={blog.blog_id} value={blog.blog_id}>
              {blog.domain}
            </option>
          ))}
        </Dropdown>
        <MenuButton>
          <MenuButtonText>Source</MenuButtonText>
        </MenuButton>
        <MenuButton>
          <MenuButtonText>View Previous Issue</MenuButtonText>
        </MenuButton>
        <MenuButton>
          <MenuButtonText>Generate Mailing</MenuButtonText>
        </MenuButton>
      </TopMenuBar>

      <BuilderBlock>
        <FormBlock>
        <p>Issue Title: {nextIssueDetails.issuetitle}</p>
        
          {/* <p>Issue Title: {nextIssueDetails.issuetitle}</p>
          <p>Issue Name: {nextIssueDetails.issuename}</p>
          <p>Issue Date: {nextIssueDetails.issuedate}</p>
          <p>Template Columns: {nextIssueDetails.template_cols}</p>
          <p>Stylesheet: {nextIssueDetails.stylesheet}</p>
          <p>
            Stylesheet URL:{' '}
            <a href={nextIssueDetails.stylesheet_url} target="_blank" rel="noopener noreferrer">
              {nextIssueDetails.stylesheet_url}
            </a>
          </p> */}
        </FormBlock>

        <PreviewBlock>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </PreviewBlock>
      </BuilderBlock>
    </BuilderContainer>
  );
};

export default ArticleBuilder;
