import React from 'react';
import styled from 'styled-components';

const DashboardContainer = styled.div`
    display: flex;
    margin-left:50px;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 60px; /* Add margin to avoid overlap with mobile header */
    @media (max-width: 768px) {
        
    margin-left:0px;
    }
`;

const DashboardItem = styled.div`
    background: white;
    padding: 20px;
    margin: 10px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    flex: 1 1 calc(33.333% - 40px);

    @media (max-width: 768px) {
        flex: 1 1 100%;
    }
`;
const DashboardBlock = styled.div`
    background: white;
    padding: 20px;
    margin: 10px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    flex: 1 1 100%;

    @media (max-width: 768px) {
        flex: 1 1 100%;
    }
`;
const DashboardTitle = styled.div`
    
    padding: 0px;
    margin-top: -30px;
    flex: 1 1 100%;
    text-align: center;

    @media (max-width: 768px) {
        flex: 1 1 100%;
    }
`;
const Dashboard = () => {
    return (
        <DashboardContainer>  
            <DashboardTitle><h1>Dashboard</h1></DashboardTitle>
            <DashboardItem>
                <h2>Statistics</h2>
                {/* Add your content here */}
            </DashboardItem>
            <DashboardItem>
                <h2>Reports</h2>
                {/* Add your content here */}
            </DashboardItem>
            <DashboardItem>
                <h2>Leads</h2>
                {/* Add your content here */}
            </DashboardItem>
            <DashboardItem>
                <h2>Total Growth</h2>
                {/* Add your content here */}
            </DashboardItem>
            <DashboardBlock>
                <h2>Mailings</h2>
                {/* Add your content here */}
            </DashboardBlock>
        </DashboardContainer>
    
    );
};

export default Dashboard;
