import React from 'react';
import styled from 'styled-components';

const SettingsContainer = styled.div`
    display: flex;
    margin-left:50px;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 60px; /* Add margin to avoid overlap with mobile header */
    @media (max-width: 768px) {
        
    margin-left:0px;
    }
`;

// const ScheduledItem = styled.div`
//     background: white;
//     padding: 20px;
//     margin: 10px;
//     border-radius: 10px;
//     box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
//     flex: 1 1 calc(33.333% - 40px);

//     @media (max-width: 768px) {
//         flex: 1 1 100%;
//     }
// `;
const SettingsBlock = styled.div`
    background: white;
    padding: 20px;
    margin: 10px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    flex: 1 1 100%;

    @media (max-width: 768px) {
        flex: 1 1 100%;
    }
`;
const SettingsTitle = styled.div`
    
    padding: 0px;
    margin-top: -30px;
    flex: 1 1 100%;
    text-align: center;

    @media (max-width: 768px) {
        flex: 1 1 100%;
    }
`;
const Settings = () => {
    return (
        <SettingsContainer>  
            <SettingsTitle><h1>Settings</h1></SettingsTitle>            
            <SettingsBlock>
                <h2>Items to check off here</h2>
                {/* Add your content here */}
            </SettingsBlock>
        </SettingsContainer>
    
    );
};

export default Settings;
