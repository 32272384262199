import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation  } from 'react-router-dom';
import LoginPage from './components/LoginPage';
import Dashboard from './components/Dashboard';
import Reports from './components/Reports';
import Builder from './components/Builder';
import Settings from './components/Settings';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPass';
import UserAccounts from './components/UserAccounts';
import Schedule from './components/Scheduled';
import ProtectedRoute from './ProtectedRoute';
import Sidebar from './components/Sidebar';
import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css';


const App = () => {
  const location = useLocation();
  const showSidebar = location.pathname !== '/' && location.pathname !== '/forgot-password' && !location.pathname.startsWith('/reset-password');

    console.log("App Component Rendered");
    return (
        
        <div className={`app-container ${showSidebar ? 'with-sidebar' : ''}`}>       
          {showSidebar && <Sidebar />}
            <div className="main-content">
              <Routes>
                  <Route path="/" element={<LoginPage />} />
                  <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
                  <Route path="/forgot-password" element={<ForgotPassword />} />
                  <Route path="/reset-password/:token" element={<ResetPassword />} />
                  <Route path="/reports" element={<ProtectedRoute><Reports /></ProtectedRoute>} />
                  <Route path="/builder" element={<ProtectedRoute><Builder /></ProtectedRoute>} />
                  <Route path="/settings" element={<ProtectedRoute><Settings /></ProtectedRoute>} />
                  <Route path="/schedule" element={<ProtectedRoute><Schedule /></ProtectedRoute>} />
                  <Route path="/user-accounts" element={<ProtectedRoute><UserAccounts /></ProtectedRoute>} />
            
              </Routes>
              </div>
            </div>
        
    );
};
const AppWrapper = () => (
  <Router>
      <App />
  </Router>
);
export default AppWrapper;