import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Label, Input, Select, Button } from './FormStyles'; // Import the shared styles
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';

const FormBlock = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    max-width: 900px;
    margin-bottom: 20px;
    background-color: transparent;
    padding: 20px;
    margin:auto;
    border-radius: 10px;   
    @media (max-width: 768px) { 
        margin-left:-20px;    
    }
`;

const FormSection = styled.div`
    flex: 1 1 calc(50% - 20px);
    display: flex;
    flex-direction: column;
    padding: 10px;
    @media (max-width: 768px) {
        flex: 1 1 100%;
        margin-bottom: 10px;
    }
`;

const AlignedButton = styled(Button)`
    align-self: flex-end;
    width: 50%;
    margin-top: 32px; /* Align with the dropdown */
    @media (max-width: 768px) {
        width: 100%;
        margin-top: 10px;
    }
`;

const UserTable = styled.table`
    width: 100%;
    max-width: 800px;
    border-collapse: collapse;
    margin-top: 20px;
    margin:auto;
`;

const TableHeader = styled.th`
    border: 1px solid #ccc;
    padding: 10px;
`;

const TableCell = styled.td`
    border: 1px solid #ccc;
    padding: 10px;
    text-align: center;
`;

const IconCell = styled.td`
    text-align: center;
    border: none;
`;

const IconButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
`;

const UserAccountsContainer = styled.div`
    display: flex;
    margin-left:50px;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 60px; /* Add margin to avoid overlap with mobile header */
    @media (max-width: 768px) {        
    margin-left:0px;
    }
`;

const UserAccountsBlock = styled.div`
    background: white;
    padding: 20px;
    margin: 10px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    flex: 1 1 100%;

    @media (max-width: 768px) {
        flex: 1 1 100%;
    }
`;
const UserAccountsTitle = styled.div`
    padding: 0px;
    margin-top: -30px;
    flex: 1 1 100%;
    text-align: center;

    @media (max-width: 768px) {
        flex: 1 1 100%;
    }
`;

const UserAccounts = () => {
    const [users, setUsers] = useState([]);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState('user');
    const [dealerName, setDealerName] = useState('');
    const [siteId, setSiteId] = useState('');
    const [message, setMessage] = useState('');
    const [editingUserId, setEditingUserId] = useState(null);

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = () => {
        axios.get('/api/users')
            .then(response => {
                setUsers(response.data);
            })
            .catch(error => {
                console.error(error);
            });
    };

    const handleCreateUser = (e) => {
        e.preventDefault();
        if (editingUserId) {
            // Update existing user
            axios.put(`/api/users/${editingUserId}`, { email, password, role, dealer_name: dealerName, site_id: siteId })
                .then(response => {
                    setMessage(response.data.msg);
                    setEditingUserId(null);
                    setEmail('');
                    setPassword('');
                    setRole('');
                    setDealerName('');
                    setSiteId('');
                    fetchUsers(); // Fetch updated users list
                })
                .catch(error => {
                    console.error(error);
                });
        } else {
            // Create new user
            axios.post('/api/create-user', { email, password, role, dealer_name: dealerName, site_id: siteId })
                .then(response => {
                    setMessage(response.data.msg);
                    setEmail('');
                    setPassword('');
                    setRole('user');
                    setDealerName('');
                    setSiteId('');
                    fetchUsers(); // Fetch updated users list
                })
                .catch(error => {
                    console.error(error);
                });
        }
    };

    const handleEditUser = (user) => {
        setEditingUserId(user.id);
        setEmail(user.email);
        setPassword(''); // Don't load the password for security reasons
        setRole(user.role);
        setDealerName(user.dealer_name);
        setSiteId(user.site_id);
    };

    const handleDeleteUser = (userId) => {
        axios.delete(`/api/users/${userId}`)
            .then(response => {
                setMessage(response.data.msg);
                fetchUsers(); // Fetch updated users list
            })
            .catch(error => {
                console.error(error);
            });
    };

    return (
        <UserAccountsContainer>  
            <UserAccountsTitle><h1>User Accounts</h1></UserAccountsTitle>            
            <UserAccountsBlock>
            <FormBlock>
                <FormSection>
                    <Label>Email</Label>
                    <Input 
                        type="email" 
                        value={email} 
                        onChange={(e) => setEmail(e.target.value)} 
                        required 
                    />
                    <Label>Password</Label>
                    <Input 
                        type="password" 
                        value={password} 
                        onChange={(e) => setPassword(e.target.value)} 
                        required 
                    />
                    <Label>Role</Label>
                    <Select value={role} onChange={(e) => setRole(e.target.value)}>
                        <option value="user">User</option>
                        <option value="production">Production</option>
                        <option value="admin">Admin</option>
                    </Select>
                </FormSection>
                <FormSection>
                    <Label>Dealer Name</Label>
                    <Input 
                        type="text" 
                        value={dealerName} 
                        onChange={(e) => setDealerName(e.target.value)} 
                        required 
                    />
                    <Label>Site ID</Label>
                    <Input 
                        type="text" 
                        value={siteId} 
                        onChange={(e) => setSiteId(e.target.value)} 
                        required 
                    />
                    <AlignedButton onClick={handleCreateUser}>
                        {editingUserId ? 'Save' : 'Create User'}
                    </AlignedButton>
                </FormSection>
            </FormBlock>
            </UserAccountsBlock>
            {message && <p>{message}</p>}
            <UserAccountsBlock>
            <UserTable>
                <thead>
                    <tr>
                        <TableHeader>ID</TableHeader>
                        <TableHeader>Email</TableHeader>
                        <TableHeader>Dealer Name</TableHeader>
                        <TableHeader>Site ID</TableHeader>
                        <TableHeader>Creation Date</TableHeader>
                        <TableHeader>Last Login Date</TableHeader>
                        <IconCell></IconCell> {/* Empty header for edit icon column */}
                        <IconCell></IconCell> {/* Empty header for trash icon column */}
                    </tr>
                </thead>
                <tbody>
                    {users.map(user => (
                        <tr key={user.id}>
                            <TableCell>{user.id}</TableCell>
                            <TableCell>{user.email}</TableCell>
                            <TableCell>{user.dealer_name}</TableCell>
                            <TableCell>{user.site_id}</TableCell>
                            <TableCell>{new Date(user.created_at).toLocaleDateString()}</TableCell>
                            <TableCell>{user.last_login ? new Date(user.last_login).toLocaleDateString() : 'Never'}</TableCell>
                            <IconCell>
                                <IconButton onClick={() => handleEditUser(user)}>
                                    <FontAwesomeIcon icon={faEdit} />
                                </IconButton>
                            </IconCell>
                            <IconCell>
                                <IconButton onClick={() => handleDeleteUser(user.id)}>
                                    <FontAwesomeIcon icon={faTrash} />
                                </IconButton>
                            </IconCell>
                        </tr>
                    ))}
                </tbody>
            </UserTable>
            </UserAccountsBlock>
        </UserAccountsContainer>
    );
};

export default UserAccounts;
