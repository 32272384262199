import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import Cookies from 'js-cookie';
import { slide as Menu } from 'react-burger-menu';
import './Sidebar.css'; // Add CSS for burger menu

const SidebarContainer = styled.div`
    width: 250px;
    max-width:100%;
    height: 100vh;
    background:  linear-gradient(132deg, #002644 -20%, #205ccc 120%);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    position: fixed;
    top: 0;
    left: 0;
    @media (max-width: 768px) {
        display: none;
    }
`;

const Logo = styled.img`
@media (max-width: 768px) {    
    width: 60px;
    margin-bottom: 20px;
}
width: 190px;  
margin-bottom: 20px;
`;

const MenuContainer = styled.div`
    width: 100%;
    flex: 1;
`;

const MenuItem = styled(NavLink)`
    display: block;
    padding: 10px 20px;
    margin: 10px 0;
    text-decoration: none;
    color: #fff;
    font-size: 18px;
    border-radius: 5px;

    &.active {
        background-color: #2294ff;
        color: #fff;
    }
    @media (max-width: 768px) {    
        color: #fff;
    }
`;

const LogoutButton = styled.button` 
    display: block;
    padding: 10px 20px;
    background-color: #2294ff;
    color: white;
    border: none;
    border-radius: 5px;    
    text-decoration: none;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: 50px; /* Adjust this value as needed */
    @media (max-width: 768px) {      
        margin-top: 30px;
    }
`;

const MobileHeader = styled.div`
    display: none;
    background-color: #f4f5f7;
    padding: 10px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
    @media (max-width: 768px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`;

const BurgerIcon = styled.div`
    @media (max-width: 768px) {    
        font-size: 24px;
        cursor: pointer;
    }
`;

const Sidebar = () => {
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false);
    const userRole = Cookies.get('userRole'); // Retrieve the userRole from the cookie


    const handleLogout = () => {
        Cookies.remove('authToken'); // Remove the auth token cookie
        navigate('/'); // Redirect to the login page
    };

    const handleStateChange = (state) => {
        setMenuOpen(state.isOpen);
    };

    const closeMenu = () => {
        setMenuOpen(false);
    };

    return (
        <>
            <MobileHeader>
                <Logo src="https://dsimages.s3.amazonaws.com/articles/DRIVING-SUCCESS-DM4-1715907501836.jpg" alt="Logo" />
                <BurgerIcon onClick={() => setMenuOpen(!menuOpen)}></BurgerIcon>
            </MobileHeader>
            <Menu right isOpen={menuOpen} onStateChange={handleStateChange}>
                {userRole === 'admin' && <MenuItem to="/dashboard" onClick={closeMenu}>Dashboard</MenuItem>}
                <MenuItem to="/reports" onClick={closeMenu}>Reports</MenuItem>
                {userRole === 'admin' && <MenuItem to="/builder" onClick={closeMenu}>Builder</MenuItem>}
                {userRole === 'admin' && <MenuItem to="/settings" onClick={closeMenu}>Settings</MenuItem>}
                {userRole === 'admin' && <MenuItem to="/schedule" onClick={closeMenu}>Scheduling</MenuItem>}
                {userRole === 'admin' && <MenuItem to="/user-accounts" onClick={closeMenu}>User Accounts</MenuItem>}
                <LogoutButton onClick={handleLogout}>Logout</LogoutButton>
            </Menu>
            <SidebarContainer>
                <Logo src="https://dsimages.s3.amazonaws.com/articles/DRIVING-SUCCESS-LOG-WHITE(1) NEW 2021-1716390635834.png" alt="Logo" />
                <MenuContainer>
                    {userRole === 'admin' && <MenuItem to="/dashboard">Dashboard</MenuItem>}
                    <MenuItem to="/reports">Reports</MenuItem>
                    {userRole === 'admin' && <MenuItem to="/builder">Builder</MenuItem>}
                    {userRole === 'admin' && <MenuItem to="/settings">Settings</MenuItem>}
                    {userRole === 'admin' && <MenuItem to="/schedule">Scheduling</MenuItem>}
                    {userRole === 'admin' && <MenuItem to="/user-accounts">User Accounts</MenuItem>}
                </MenuContainer>
                <LogoutButton onClick={handleLogout}>Logout</LogoutButton>
            </SidebarContainer>
        </>
    );
};

export default Sidebar;
